<template>

  <div>

      <div v-for="(item , i) in VIDEO_SOURCES" :key="item.value + i" class="mb-3">

        <h5 class="wsWHITE text-no-wrap font-weight-regular mb-2 d-flex align-center shorten-text">
          <v-icon class="mr-2" :color="wsACCENT">mdi-video</v-icon>
          {{ item.name  }}
        </h5>
        <v-sheet
            style="position: relative"
            class="d-flex align-center wsRoundedSemi overflow-hidden"
            height="112"
            :color="wsACCENT"
        >
          <ws-switch small v-model="$store.state.stream.videoSources[i].active" style="position: absolute; top: 16px; right: -3px; z-index: 1"  />
          <video
              :ref="`video${i}`"
              autoplay
              width="100%"
              height="auto"
              :class="[{grayscale : !item.active}]"
          />
        </v-sheet>

      </div>


    <div>
      <ft-select
          @input="addStream"
          :items="CAMERAS_SELECT"
      >
        <v-btn class="mx-auto mt-6"  icon>
          <v-icon :color="wsWHITE" large>mdi-plus-circle</v-icon>
        </v-btn>
      </ft-select>
    </div>






    </div>

</template>

<script>
import webinars from "@modules/webinars/mixins/webinars";

export default {
  name: "wsLeftSidebar.vue",
  mixins: [webinars],
  props: {
    toggleCamera: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      width : 0,
      videoSettingsPanel: null
    }
  },
  computed: {

  },
  watch: {
    VIDEO_SOURCES : {
      handler() {
        this.initVideoSources()
      },
      deep : true
    }
  },
  methods: {
    async addStream(deviceId) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: deviceId,
          width: { ideal: 640 },
          height: { ideal: 480 }
        }
      });

      stream.getTracks().forEach((track) => {
        track.addEventListener('ended', () => {
          this.notify('Device disconnected' , 'warning')
          this.$store.state.stream.videoSources = this.$store.state.stream.videoSources
              .filter(el => el.value !== deviceId)
        });
      });

      let name = deviceId
      let deviceItem =  this.CAMERAS_SELECT.find(el => el.value === deviceId)
      if (deviceItem && deviceItem.text) {
        name = deviceItem.text
      }
      this.$store.state.stream.videoSources.push({
        value : deviceId,
        name : name,
        stream : stream,
        isLocal : true,
        active : true  ,
        main : true,
      })

    },
    initVideoSources() {
      setTimeout(()=> {
        this.VIDEO_SOURCES.forEach((source,i) => {
          let videoDom = this.$refs[`video${i}`];
          if (videoDom && videoDom[0]) {
            videoDom = videoDom[0]
            videoDom.srcObject = source.stream
          }
        })
      },300)

    },
  },
  mounted() {
    this.width = 179
    setTimeout(() => {
      this.initVideoSources()
    }, 300)
  }
}
</script>
<style scoped lang="scss">
.grayscale {
  filter: grayscale(100%);
}
</style>
